<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="ma-5"> </v-col>
      <v-col>
        <!-- Error message -->
        <v-container v-if="error?.enabled">
          <v-alert color="red" elevation="9" type="error" dismissible>
            {{ error.message }}
          </v-alert>
        </v-container>
      </v-col>

      <!-- Search bar -->
      <v-col cols="12">
        <v-row justify="center">
          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="dateFromMenu"
                v-model="searchParameter.dateFrom.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchParameter.dateFrom.value"
                    label="Date From"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParameter.dateFrom.date"
                  :active-picker.sync="searchParameter.dateFrom.activePicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  @input="onDateFromInput"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                ref="dateToMenu"
                v-model="searchParameter.dateTo.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchParameter.dateTo.value"
                    label="Date To"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParameter.dateTo.date"
                  :active-picker.sync="searchParameter.dateTo.activePicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  @input="onDateToInput"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="searchParameter.query"
                label="Query"
                required
                prepend-icon="mdi-database-search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-col cols="5" md="1">
            <v-btn class="mr-1 mb-2" @click="fetchEmails"> Search </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- Loading -->
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"
          class="ma-12"
        ></v-progress-circular>
      </v-row>

      <!-- Email list -->
      <v-col class="mb-4" v-if="emailList.enabled">
        <v-row justify="center">
          <v-card min-width="100%">
            <v-card-title>
              Email List
              <v-spacer></v-spacer>
              <v-text-field
                v-model="emailList.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              @click:row="fetchEmailDetails"
              item-key="id"
              :headers="emailList.headers"
              :items="emailList.items"
              :search="emailList.search"
              :footer-props="{
                'items-per-page-options': [50, 100, 150],
              }"
              :items-per-page="50"
            ></v-data-table>
          </v-card>
        </v-row>
      </v-col>

      <!-- Email details -->
      <v-card class="mx-auto" tile v-if="emailDetails.enabled" min-width="100%">
        <!-- First row: back-button and title -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="1">
              <v-btn
                value-align="left"
                depressed
                fab
                large
                @click="
                  emailDetails.enabled = false;
                  emailList.enabled = true;
                "
              >
                <v-img
                  lazy-src="../assets/back-icon-ios-blue.png"
                  max-height="50"
                  max-width="50"
                  src="../assets/back-icon-ios-blue.png"
                ></v-img>
              </v-btn>
            </v-col>
            <v-col cols="12" md="11">
              <v-card-title primary-title class="justify-center">
                Email "{{ emailDetails.items.left.subject }}"
              </v-card-title>
            </v-col>
          </v-row>
        </v-col>
        <!-- Second row: details and body -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-list-item
                two-line
                v-for="(value, key) in emailDetails.items.left"
                :key="key"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ key }}</v-list-item-title>
                  <v-list-item-subtitle> {{ value }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="6">
              <v-list-item
                two-line
                v-for="(value, key) in emailDetails.items.right"
                :key="key"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ key }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">
                    {{ value }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    searchParameter: {
      dateFrom: {
        activePicker: null,
        date: null,
        menu: false,
        value: "",
      },
      dateTo: {
        activePicker: null,
        date: null,
        menu: false,
        value: "",
      },
      query: "",
    },
    error: {
      enabled: false,
      message: "",
    },
    loading: false,
    emailList: {
      enabled: false,
      headers: [
        { text: "ID", value: "id", align: " d-none" },
        { text: "Sender", value: "sender" },
        { text: "Receiver", value: "receiver" },
        { text: "Title", value: "title" },
        { text: "State", value: "state" },
        { text: "Timestamp", value: "timestamp" },
      ],
      items: [],
      search: "",
    },
    emailDetails: {
      enabled: false,
      items: [],
    },
  }),
  beforeMount() {
    this.setSearchParameterDates();
    this.fetchEmails();
  },
  methods: {
    setSearchParameterDates: async function () {
      const date = new Date();

      this.searchParameter.dateTo.value = `${date.toLocaleDateString("en-US", {
        year: "numeric",
      })}-${date
        .toLocaleDateString("en-US", { month: "numeric" })
        .padStart(2, "0")}-${date
        .toLocaleDateString("en-US", { day: "numeric" })
        .padStart(2, "0")}`;

      date.setDate(date.getDate() - 7);

      this.searchParameter.dateFrom.value = `${date.toLocaleDateString(
        "en-US",
        { year: "numeric" }
      )}-${date
        .toLocaleDateString("en-US", { month: "numeric" })
        .padStart(2, "0")}-${date
        .toLocaleDateString("en-US", { day: "numeric" })
        .padStart(2, "0")}`;

      this.searchParameter.dateTo.date = this.searchParameter.dateTo.value;
      this.searchParameter.dateFrom.date = this.searchParameter.dateFrom.value;
    },
    fetchEmails: async function () {
      await this.hideError();
      await this.displayLoading();
      await this.hideEmailList();
      await this.clearEmailList();
      await this.hideEmailDetails();

      let dateFrom = this.searchParameter.dateFrom.value;
      let dateTo = this.searchParameter.dateTo.value;
      let query = this.searchParameter.query;

      // Data validation

      // Accepted date format: 2022-08-04
      const dateRegex =
        /^\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/;

      if (!dateRegex.test(dateFrom)) {
        await this.hideLoading();
        await this.displayError(
          'Invalid "Date From" parameter, use YYYY-MM-DD'
        );
        return;
      }

      if (!dateTo || dateTo == "") {
        dateTo =
          new Date().toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          new Date().toLocaleDateString("en-US", { month: "numeric" }) +
          "-" +
          new Date().toLocaleDateString("en-US", { day: "numeric" });
      }

      if (!dateRegex.test(dateTo)) {
        await this.hideLoading();
        await this.displayError('Invalid "Date To" parameter, use YYYY-MM-DD');
        return;
      }

      if (new Date(dateFrom) > new Date(dateTo)) {
        await this.hideLoading();
        await this.displayError('"Date To" must come after the "Date From"');
        return;
      }

      if (query.length > 40) {
        await this.hideLoading();
        await this.displayError('Invalid "Query" parameter, use a shorter one');
        return;
      }

      // Data retrieval

      let response;

      try {
        const axios = require("axios");
        response = await axios.get("messages", {
          baseURL: process.env.VUE_APP_API_BASE_URL,
          params: {
            dateFrom: this.searchParameter.dateFrom.value,
            dateTo: this.searchParameter.dateTo.value,
            query: this.searchParameter.query || "*",
          },
        });
      } catch (err) {
        await this.hideLoading();
        await this.displayError(
          `Error while retrieving emails from Mailchimp: ${err}`
        );
        console.log(JSON.stringify(err));
        return;
      }

      try {
        if (!response?.data) {
          response.data = [];
        }

        response.data.forEach((e) => {
          this.emailList.items.push({
            id: e._id,
            sender: e.sender,
            receiver: e.email,
            title: e.subject,
            state: e.state,
            timestamp: e["@timestamp"],
          });
        });
      } catch (err) {
        await this.hideLoading();
        await this.displayError(
          "Error while loading the received emails into the table: " + err
        );
        return;
      }

      await this.hideLoading();
      await this.displayEmailList();
    },
    fetchEmailDetails: async function (e) {
      await this.hideError();
      await this.displayLoading();
      await this.hideEmailList();
      await this.hideEmailDetails();

      let response;

      try {
        const axios = require("axios");
        response = await axios.get(`messages/${e.id}`, {
          baseURL: process.env.VUE_APP_API_BASE_URL,
        });

        // Remove attachments contents
        if (response.data?.attachments) {
          response.data.attachments.forEach((a) => delete a.content);
        }

        this.emailDetails.items.left = {
          ...response.data,
        };

        if (this.emailDetails.items.left?.html) {
          delete this.emailDetails.items.left.html;
        }
        if (this.emailDetails.items.left?.text) {
          delete this.emailDetails.items.left.text;
        }

        this.emailDetails.items.right = {
          html: response.data?.html || "",
          text: response.data?.text || "",
        };
      } catch (err) {
        await this.hideLoading();
        await this.displayError("Error while retrieving email details");
        return;
      }

      await this.hideLoading();
      await this.displayEmailDetails();
    },
    displayError: async function (message) {
      this.error.message = message;
      this.error.enabled = true;
    },
    hideError: async function () {
      this.error.message = "";
      this.error.enabled = false;
    },
    displayLoading: async function () {
      this.loading = true;
    },
    hideLoading: async function () {
      this.loading = false;
    },
    displayEmailList: async function () {
      this.emailList.enabled = true;
    },
    hideEmailList: async function () {
      this.emailList.enabled = false;
    },
    clearEmailList: async function () {
      this.emailList.items = [];
    },
    displayEmailDetails: async function () {
      this.emailDetails.enabled = true;
    },
    hideEmailDetails: async function () {
      this.emailDetails.enabled = false;
    },
    onDateFromInput(date) {
      this.searchParameter.dateFrom.value = date;
      this.searchParameter.dateFrom.menu = false;
    },
    onDateToInput(date) {
      this.searchParameter.dateTo.value = date;
      this.searchParameter.dateTo.menu = false;
    },
  },
  watch: {
    dateFromMenu(val) {
      val &&
        setTimeout(() => (this.searchParameter.dateFrom.activePicker = "YEAR"));
    },
    dateToMenu(val) {
      val &&
        setTimeout(() => (this.searchParameter.dateTo.activePicker = "YEAR"));
    },
  },
};
</script>

<style scoped>
.canvas__title {
  font-family: "Proxima Nova Black", "Helvetica Neue", Arial, Helvetica,
    sans-serif !important;
  font-weight: 900 !important;
  font-style: normal !important;
  line-height: 1.15em;
  text-transform: uppercase;
  transition-delay: 0.2s;
  font-size: 40px;
  margin: 0 auto 20px;
  color: inherit;
}

.canvas__text {
  font-size: 20px;
  max-width: 738px;
  line-height: 38px;
  transition-delay: 0.4s;
  letter-spacing: 0.2px;
  text-transform: none;
  margin: 0 auto 80px;
  color: inherit;
}
</style>
