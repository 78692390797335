<template>
  <v-app>
    <v-app-bar app color="secondary">
        <a href="https://www.azimut.it/it/">
        <v-img 
          alt="Azimut Logo"
          class="shrink mt-1"
          contain
          min-width="150"
          src="./assets/azimut-logo.png"
          width="150"
        >
        </v-img>
      </a>
  



      
    </v-app-bar>

    <v-main class="pa-6">
      <AzimutDisplayEmails />
    </v-main>
  </v-app>
</template>

<script>
import AzimutDisplayEmails from "./components/AzimutDisplayEmails";

export default {
  name: "App",

  components: {
    AzimutDisplayEmails,
  },

  data: () => ({
    //
  }),
};
</script>
