var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{staticClass:"ma-5",attrs:{"cols":"12"}}),_c('v-col',[(_vm.error?.enabled)?_c('v-container',[_c('v-alert',{attrs:{"color":"red","elevation":"9","type":"error","dismissible":""}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{ref:"dateFromMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date From","prepend-icon":"mdi-calendar"},model:{value:(_vm.searchParameter.dateFrom.value),callback:function ($$v) {_vm.$set(_vm.searchParameter.dateFrom, "value", $$v)},expression:"searchParameter.dateFrom.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.searchParameter.dateFrom.menu),callback:function ($$v) {_vm.$set(_vm.searchParameter.dateFrom, "menu", $$v)},expression:"searchParameter.dateFrom.menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.searchParameter.dateFrom.activePicker,"max":new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){return _vm.$set(_vm.searchParameter.dateFrom, "activePicker", $event)},"update:active-picker":function($event){return _vm.$set(_vm.searchParameter.dateFrom, "activePicker", $event)},"input":_vm.onDateFromInput},model:{value:(_vm.searchParameter.dateFrom.date),callback:function ($$v) {_vm.$set(_vm.searchParameter.dateFrom, "date", $$v)},expression:"searchParameter.dateFrom.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{ref:"dateToMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date To","prepend-icon":"mdi-calendar"},model:{value:(_vm.searchParameter.dateTo.value),callback:function ($$v) {_vm.$set(_vm.searchParameter.dateTo, "value", $$v)},expression:"searchParameter.dateTo.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.searchParameter.dateTo.menu),callback:function ($$v) {_vm.$set(_vm.searchParameter.dateTo, "menu", $$v)},expression:"searchParameter.dateTo.menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.searchParameter.dateTo.activePicker,"max":new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){return _vm.$set(_vm.searchParameter.dateTo, "activePicker", $event)},"update:active-picker":function($event){return _vm.$set(_vm.searchParameter.dateTo, "activePicker", $event)},"input":_vm.onDateToInput},model:{value:(_vm.searchParameter.dateTo.date),callback:function ($$v) {_vm.$set(_vm.searchParameter.dateTo, "date", $$v)},expression:"searchParameter.dateTo.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Query","required":"","prepend-icon":"mdi-database-search"},model:{value:(_vm.searchParameter.query),callback:function ($$v) {_vm.$set(_vm.searchParameter, "query", $$v)},expression:"searchParameter.query"}})],1)],1),_c('v-col',{attrs:{"cols":"5","md":"1"}},[_c('v-btn',{staticClass:"mr-1 mb-2",on:{"click":_vm.fetchEmails}},[_vm._v(" Search ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[(_vm.loading)?_c('v-progress-circular',{staticClass:"ma-12",attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(_vm.emailList.enabled)?_c('v-col',{staticClass:"mb-4"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{attrs:{"min-width":"100%"}},[_c('v-card-title',[_vm._v(" Email List "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.emailList.search),callback:function ($$v) {_vm.$set(_vm.emailList, "search", $$v)},expression:"emailList.search"}})],1),_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.emailList.headers,"items":_vm.emailList.items,"search":_vm.emailList.search,"footer-props":{
              'items-per-page-options': [50, 100, 150],
            },"items-per-page":50},on:{"click:row":_vm.fetchEmailDetails}})],1)],1)],1):_vm._e(),(_vm.emailDetails.enabled)?_c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","min-width":"100%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"value-align":"left","depressed":"","fab":"","large":""},on:{"click":function($event){_vm.emailDetails.enabled = false;
                _vm.emailList.enabled = true;}}},[_c('v-img',{attrs:{"lazy-src":require("../assets/back-icon-ios-blue.png"),"max-height":"50","max-width":"50","src":require("../assets/back-icon-ios-blue.png")}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-card-title',{staticClass:"justify-center",attrs:{"primary-title":""}},[_vm._v(" Email \""+_vm._s(_vm.emailDetails.items.left.subject)+"\" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},_vm._l((_vm.emailDetails.items.left),function(value,key){return _c('v-list-item',{key:key,attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(key))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(value))])],1)],1)}),1),_c('v-col',{attrs:{"cols":"12","md":"6"}},_vm._l((_vm.emailDetails.items.right),function(value,key){return _c('v-list-item',{key:key,attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(key))]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(value))])],1)],1)}),1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }